import TagManager from 'react-gtm-module';

const initGtm = () => {
  // only run in production
  const env = process.env.ENVIRONMENT;
  const gtmId = process.env.GTM_ID;
  if (env !== 'production' || !gtmId) {
    return;
  }

  // trigger
  TagManager.initialize({gtmId});
};
export default initGtm;
